import React from 'react'
import {Link} from 'gatsby'
import SimplePage from '../components/SimplePage'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

const Privacy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <SimplePage>
      <h2>Privacy Policy</h2>
      <p>
        In questa pagina sono descritte le modalità di gestione del sito web{' '}
        <strong>informarsiconviene.it</strong> con riguardo al trattamento dei
        dati personali degli utenti che lo consultano e ne utilizzano i servizi.
        La presente informativa è resa anche ai sensi degli artt. 13 del
        Regolamento 2016/679/UE (“GDPR”) a coloro che navigano o utilizzano i
        servizi web di OCF. Le informazioni qui fornite non riguardano altri
        siti, pagine o servizi online raggiungibili tramite link ipertestuali
        eventualmente presenti sul Sito.
      </p>
      <p>
        <strong>
          1. TITOLARE DEL TRATTAMENTO E RESPONSABILE PER LA PROTEZIONE DEI DATI
          PERSONALI (“DPO”)
        </strong>
      </p>
      <p>
        Il Titolare dei trattamenti svolti attraverso il Sito (di seguito
        “Titolare”) è l’Organismo di vigilanza e tenuta dell’albo unico dei
        Consulenti Finanziari (di seguito “OCF”), con sede legale in via
        Tomacelli 146 - 00186, Roma, email: info@organismocf.it.
      </p>
      <p>
        Il Responsabile per la protezione dei dati personali (di seguito “Data
        Protection Officer” o “DPO”) è contattabile ai seguenti indirizzi:
      </p>
      <p>
        e-mail, all’indirizzo{' '}
        <a href="mailto:dpo@organismocf.it?subject=DPO">dpo@organismocf.it</a>
      </p>
      <p>
        posta ordinaria, all’indirizzo Via Tomacelli n. 146 – 00187, Roma, c.a.
        del Data Protection Officer
      </p>
      <p>
        <strong>2. DATI PERSONALI E FINALITA’DEL TRATTAMENTO</strong>
      </p>
      <p>
        La informiamo che in caso di consultazione del Sito OCF tratta dati
        relativi a persone identificate o identificabili. In particolare, i dati
        personali trattati attraverso il Sito sono i seguenti:
      </p>
      <p>
        <strong>Dati di navigazione</strong>
      </p>
      <p>
        I sistemi informatici e le procedure software preposte al funzionamento
        del Sito acquisiscono, nel corso del loro normale esercizio, alcuni dati
        personali la cui trasmissione è implicita nell’uso dei protocolli di
        comunicazione di Internet. Si tratta di informazioni che non sono
        raccolte per essere associate a interessati identificati, ma che per
        loro stessa natura potrebbero, attraverso elaborazioni ed associazioni
        con dati detenuti da terzi, permettere di identificare gli utenti.
      </p>
      <p>
        In questa categoria di dati rientrano gli indirizzi IP o i nomi a
        dominio dei computer utilizzati dagli utenti che si connettono al Sito,
        gli indirizzi in notazione URI (Uniform Resource Identifier) delle
        risorse richieste, l’orario della richiesta, il metodo utilizzato nel
        sottoporre la richiesta al server, la dimensione del file ottenuto in
        risposta, il codice numerico indicante lo stato della risposta data dal
        server (buon fine, errore, ecc.) ed altri parametri relativi al sistema
        operativo e all’ambiente informatico dell’utente.
      </p>
      <p>
        Questi dati sono necessari per garantire all’utente la navigazione sul
        Sito e la fruizione dei servizi forniti attraverso il Sito e sono
        utilizzati al solo fine di ricavare informazioni statistiche anonime
        sull’uso del sito (pagine più visitate, numero di visitatori per fascia
        oraria o giornaliera, aree geografiche di provenienza, ecc.) e
        migliorarne la fruibilità. I dati potrebbero essere inoltre utilizzati
        per l’accertamento di responsabilità in caso di ipotetici reati
        informatici ai danni del Sito.
      </p>
      <p>
        <strong>Cookie</strong>
      </p>
      <p>
        Per avere maggiori informazioni sulla tipologia di cookie utilizzati è
        possibile consultare la sezione specifica all’indirizzo:{' '}
        <Link to="/cookie-policy">Cookie Policy</Link>.
      </p>

      <p>
        <strong>
          3. BASE GIURIDICA E NATURA OBBLIGATORIA O FACOLTATIVA DEL TRATTAMENTO
        </strong>
      </p>
      <p>
        I Suoi dati personali verranno trattati, eventualmente con il Suo
        consenso ove necessario, per le seguenti finalità, ove applicabili:
        (i)consentire la navigazione del Sito e l’erogazione dei servizi ivi
        messi a diposizione dal Titolare, in particolare la visualizzazione dei
        contenuti video disponibili sul Sito, ai sensi dell’art. 6.1., lett. b),
        nonché migliorarne la fruibilità, ai sensi dell’art. 6.1., lett. f), del
        GDPR; (ii) assolvimento di obblighi stabiliti dalla legge, dai
        regolamenti e dalla normativa europea nonché da disposizioni impartite
        da autorità, anche attinenti allo svolgimento dei compiti di interesse
        pubblico di vigilanza e tenuta dell’albo unico dei consulenti
        finanziari, ai sensi dell’art. 6.1., lett. c); (iii) finalità difensive,
        ai sensi degli artt. 6.1., lett. f) e 9.2.f) del GDPR.
      </p>
      <p>
        Il conferimento dei Suoi dati personali per navigare e usufruire dei
        servizi erogati sul Sito è obbligatorio.
      </p>
      <p>
        <strong>4. DESTINATARI DEI DATI PERSONALI</strong>
      </p>
      <p>
        I Suoi dati personali non saranno comunicati a terzi né diffusi, se non
        nei casi previsti dal diritto nazionale e dell’Unione europea; in
        particolare, i Suoi dati potranno essere comunicati, nei limiti di
        quanto previsto dalle leggi e dai regolamenti in materia di trattamento
        dei dati personali, a: (i) persone fisiche incaricate da OCF in ragione
        dell’espletamento delle loro mansioni lavorative; (ii) fornitori di
        servizi in qualità di responsabili del trattamento (ad esempio gli
        hosting provider ovvero soggetti delegati a svolgere attività di
        manutenzione tecnica); (iii) soggetti, enti o autorità a cui sia
        obbligatorio comunicare i Suoi dati personali in forza di disposizioni
        di legge o di ordini delle autorità, inclusi i casi di esercizio del
        diritto di accesso e dei poteri informativi. L’elenco completo ed
        aggiornato dei destinatari dei dati potrà essere richiesto al Titolare
        e/o al DPO, ai recapiti sopra indicati.
      </p>
      <p>
        <strong>5. TRASFERIMENTI DEI DATI PERSONALI</strong>
      </p>
      <p>
        I dati personali saranno trattati da OCF all’interno del territorio
        dell’Unione europea. Qualora per questioni di natura tecnica e/o
        operativa si renda necessario avvalersi di soggetti ubicati al di fuori
        dell’Unione europea, oppure si renda necessario trasferire alcuni dei
        dati raccolti verso sistemi tecnici e servizi gestiti in cloud e
        localizzati al di fuori dell’area dell’Unione europea, il trattamento
        sarà regolato in conformità a quanto previsto dal capo V del Regolamento
        e autorizzato in base a specifiche decisioni dell’Unione europea.
        Saranno quindi adottate tutte le cautele necessarie al fine di garantire
        la più totale protezione dei dati personali basando tale trasferimento:
        a) su decisioni di adeguatezza dei paesi terzi destinatari espressi
        dalla Commissione europea; b) su garanzie adeguate espresse dal soggetto
        terzo destinatario ai sensi dell’art. 46 del GDPR; c) sull’adozione di
        norme vincolanti d’impresa, cd. Corporate binding rules. È possibile
        avere maggiori informazioni, su richiesta, presso il Titolare e/o il DPO
        ai contatti sopraindicati.
      </p>
      <p>
        <strong>6. CONSERVAZIONE DEI DATI</strong>
      </p>
      <p>
        I dati saranno trattati e conservati per il tempo necessario al
        conseguimento delle finalità per cui sono stati raccolti nel rispetto
        dei principi di minimizzazione del trattamento e di limitazione di
        conservazione. È fatta salvo in ogni caso l’ulteriore conservazione
        prevista dalla normativa applicabile, tra cui quella prevista dall’art.
        2946 cod. civ. È possibile avere maggiori informazioni, su richiesta,
        presso il Titolare e/o il DPO ai contatti sopraindicati.
      </p>
      <p>
        <strong>7. DIRITTI DEGLI INTERESSATI</strong>
      </p>
      <p>
        Gli interessati hanno il diritto di chiedere di accedere ai dati
        personali e la rettifica o la cancellazione degli stessi o la
        limitazione del trattamento, di ottenere la portabilità nei casi
        previsti (artt. 15 e ss., GDPR) e di proporre reclamo al Garante per la
        Protezione dei Dati Personali (art. 77, GDPR).
      </p>
      <p>
        Con riferimento al diritto di opposizione previsto dall’art. 21 del
        GDPR, potrà formulare una richiesta nella quale dare evidenza delle
        ragioni che giustifichino l’opposizione. OCF si riserva di valutare la
        Sua istanza che non sarà accettata in caso di esistenza di motivi
        legittimi cogenti per procedere al trattamento che prevalgono sugli
        interessi, sui diritti e sulle libertà dell’interessato oppure per
        l’accertamento, l’esercizio o la difesa di un diritto in sede
        giudiziaria.
      </p>
      <p>
        Le istanze devono essere trasmesse al Titolare e/o al DPO ai dati di
        contatto sopra indicati.
      </p>
      <p>
        <strong>8. MODIFICHE</strong>
      </p>
      <p>
        OCF si riserva di modificare la presente informativa o di aggiornarne
        semplicemente il contenuto, in tutto o in parte, anche a causa di
        variazioni della normativa applicabile. OCF La invita quindi a visitare
        con regolarità questa sezione del Sito per prendere cognizione della più
        recente ed aggiornata versione della privacy policy, in modo da essere
        sempre aggiornato sui dati raccolti e sull’uso che ne fa OCF.
      </p>
    </SimplePage>
  </Layout>
)

export default Privacy
